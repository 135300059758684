
import { defineComponent, computed, PropType, ref } from "vue";
import ToggleCheckbox from "@/components/atomics/ToggleCheckbox.vue";
import TemplateSignerAddPopup from "@/components/popups/templates/TemplateSignerAddPopup.vue";
import DragContainer from "@/components/atomics/drag/DragContainer.vue";
import Signer from "@/models/Signer";
import TextButton from "@/components/atomics/button/TextButton.vue";
import usePopupControl from "@/lib/compositional-logic/usePopupControl";
import UpdateTextPopup from "@/components/popups/UpdateTextPopup.vue";
import { useVModel } from "@vueuse/core";
import ItemInList from "@/components/atomics/list-item/ItemInList.vue";
import { useI18n } from "vue-i18n";
import useBreakpoint from "@/lib/compositional-logic/useBreakpoint";

export default defineComponent({
  name: "TemplateSigner",
  inheritAttrs: false,
  components: {
    ToggleCheckbox,
    TemplateSignerAddPopup,
    DragContainer,
    TextButton,
    UpdateTextPopup,
    ItemInList,
  },
  props: {
    is2FARequired: {
      type: Boolean,
      default: false,
    },
    isSignerTransferAllowed: {
      type: Boolean,
      default: false,
    },
    isOnlyPartnerXIDRequired: {
      type: Boolean,
      default: false,
    },
    isXIDRequired: {
      type: Boolean,
      default: false,
    },
    needTimeStamp: {
      type: Boolean,
      default: true, 
    },
    signers: {
      type: Array as PropType<Array<Signer>>,
      required: true,
    },
  },
  emits: [
    "update:is2FARequired",
    "update:isSignerTransferAllowed",
    "update:isOnlyPartnerXIDRequired",
    "update:isXIDRequired",
    "update:needTimeStamp",
    "update:signers",
  ],
  beforeRouteEnter(to, from, next) {
    if (
      from.name == "TemplateFileAndInfo" ||
      from.name == "TemplateVars" ||
      to.params.id
    )
      next();
    else next({ name: "TemplateFileAndInfo" });
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { isMobile } = useBreakpoint();

    const getOnOff = (state: boolean) => {
      if (state) return t("template.signers.on");
      return t("template.signers.off");
    };

    const securityItems = computed(() => [
      { name: t("template.signers.requiredXID"), value: localIsXIDRequired },
      {
        name: t("template.signers.senderRequiredXID"),
        value: localIsOnlyPartnerXIDRequired,
      },
      { name: t("template.signers.twoFactor"), value: localIs2FARequired },
      {
        name: t("template.summary.allowTransfer"),
        value: localIsSignerTransferAllowed,
      },
      {
        name: t("template.signers.needTimeStamp"),
        value: localNeedTimeStamp,
      },
    ]);

    const localIs2FARequired = useVModel(props, "is2FARequired", emit);
    const localIsSignerTransferAllowed = useVModel(
      props,
      "isSignerTransferAllowed",
      emit
    );
    const localIsOnlyPartnerXIDRequired = useVModel(
      props,
      "isOnlyPartnerXIDRequired",
      emit
    );
    const localIsXIDRequired = useVModel(props, "isXIDRequired", emit);
    const localNeedTimeStamp = useVModel(props, "needTimeStamp", emit);

    const localSigners = computed({
      get(): Signer[] {
        return props.signers;
      },

      set(value: Signer[]) {
        emit(
          "update:signers",
          value.map((val, index) => ({
            order: index + 1,
            placeHolder: val.placeHolder,
          }))
        );
      },
    });

    const indexEditPlaceHolder = ref(0);

    const removeSigners = (index: number) => {
      if (index >= 0) localSigners.value.splice(index, 1);
    };

    const { popupState, openPopup } = usePopupControl();

    const onAddSigner = (payload: Signer[]) => {
      localSigners.value = payload;
    };

    const editPlaceholder = (index: number) => {
      indexEditPlaceHolder.value = index;
      openPopup("updateText");
    };

    const editSignerPopupInfo = computed(() => ({
      initialTextValue:
        localSigners.value[indexEditPlaceHolder.value]?.placeHolder,
      title: t("template.signers.editPopupTitle"),
      label: t("template.signer"),
      fieldName: "placeHolder",
      payloadType: "object",
      action: (signer: Record<string, string>) =>
        (() => {
          localSigners.value[indexEditPlaceHolder.value].placeHolder =
            signer.placeHolder;
        })(),
    }));

    return {
      localIsXIDRequired,
      localIsOnlyPartnerXIDRequired,
      localIsSignerTransferAllowed,
      localIs2FARequired,
      localNeedTimeStamp,
      localSigners,
      editSignerPopupInfo,
      removeSigners,
      onAddSigner,
      openPopup,
      editPlaceholder,
      indexEditPlaceHolder,
      popupState,
      getOnOff,
      securityItems,
      isMobile,
    };
  },
});

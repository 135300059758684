import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "popup-input-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableSelect = _resolveComponent("TableSelect")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    title: _ctx.$t('template.signers.addBtn'),
    "popup-key": _ctx.popupKey
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FlatButton, {
        onOnClick: _ctx.onAdd,
        text: _ctx.$t('common.addTo'),
        class: "w-full"
      }, null, 8, ["onOnClick", "text"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("template.signer")), 1),
      _createVNode(_component_TableSelect, {
        "search-hint": _ctx.$t('template.addPlaceholder'),
        "limit-number": 5,
        placeHolder: _ctx.placeHolder,
        "onUpdate:placeHolder": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.placeHolder) = $event)),
        selectedValues: _ctx.selectedUsers,
        "onUpdate:selectedValues": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedUsers) = $event))
      }, null, 8, ["search-hint", "placeHolder", "selectedValues"])
    ]),
    _: 1
  }, 8, ["title", "popup-key"]))
}

import { defineComponent, PropType } from "vue";
import Chip from "@/components/atomics/Chip.vue";
import { useVModel } from "@vueuse/core";

interface PlaceHolder {
  placeHolder: string;
}

export default defineComponent({
  name: "TableSelect",
  components: {
    Chip,
  },
  props: {
    selectedValues: {
      type: Array as PropType<Array<PlaceHolder>>,
      default: () => [],
    },
    limitNumber: { type: Number, default: Infinity },
    searchHint: { type: String, default: "" },
    placeHolder: { type: String, required: true },
  },
  emits: ["update:placeHolder"],
  setup(props, { emit }) {
    const placeHolder = useVModel(props, "placeHolder", emit);
    const selectedItems = useVModel(props, "selectedValues", emit);

    const removeItem = (index: number) => {
      selectedItems.value.splice(index, 1);
    };

    const createTag = () => {
      if (
        !placeHolder.value ||
        selectedItems.value.length >= props.limitNumber ||
        selectedItems.value.some(
          (selectedItem) => selectedItem.placeHolder == placeHolder.value
        )
      )
        return;

      const item = {
        placeHolder: placeHolder.value,
      };

      selectedItems.value.push(item);
      placeHolder.value = "";
    };

    return {
      removeItem,
      createTag,
      localPlaceHolder: placeHolder,
      selectedItems,
    };
  },
});

import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d89ec36"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section-header big mt-3!" }
const _hoisted_2 = { class: "section-header big mt-5!" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = {
  key: 0,
  class: "empty-info"
}
const _hoisted_5 = {
  key: 1,
  class: "state"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_DragContainer = _resolveComponent("DragContainer")!
  const _component_ToggleCheckbox = _resolveComponent("ToggleCheckbox")!
  const _component_ItemInList = _resolveComponent("ItemInList")!
  const _component_TemplateSignerAddPopup = _resolveComponent("TemplateSignerAddPopup")!
  const _component_UpdateTextPopup = _resolveComponent("UpdateTextPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createTextVNode(_toDisplayString(_ctx.$t("template.signers.document")) + " ", 1),
      _createVNode(_component_TextButton, {
        onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openPopup('templateSignerAdd'))),
        icon: require('icons/add.svg'),
        text: _ctx.$t('template.signers.addBtn'),
        "is-show-text": !_ctx.isMobile
      }, null, 8, ["icon", "text", "is-show-text"])
    ]),
    _createVNode(_component_DragContainer, {
      items: _ctx.localSigners,
      message: _ctx.$t('template.signers.emptyMessage'),
      onOnDelete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.removeSigners($event))),
      onOnDragDone: _cache[2] || (_cache[2] = ($event: any) => (_ctx.localSigners = $event)),
      onOnEdit: _ctx.editPlaceholder
    }, null, 8, ["items", "message", "onOnEdit"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("template.signers.security")), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.securityItems, (security, index) => {
      return (_openBlock(), _createBlock(_component_ItemInList, {
        key: index,
        "has-divider": index != _ctx.securityItems.length - 1,
        class: "security-item"
      }, {
        head: _withCtx(() => [
          _createElementVNode("p", _hoisted_3, _toDisplayString(security.name), 1)
        ]),
        body: _withCtx(() => [
          (index == 1 && !_ctx.securityItems[0].value.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.getOnOff(security.value.value)), 1))
        ]),
        tail: _withCtx(() => [
          (!(index == 1 && !_ctx.securityItems[0].value.value))
            ? (_openBlock(), _createBlock(_component_ToggleCheckbox, {
                key: 0,
                checked: _ctx.securityItems[index].value.value,
                "onUpdate:checked": ($event: any) => ((_ctx.securityItems[index].value.value) = $event)
              }, null, 8, ["checked", "onUpdate:checked"]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["has-divider"]))
    }), 128)),
    (_ctx.popupState.templateSignerAdd)
      ? (_openBlock(), _createBlock(_component_TemplateSignerAddPopup, {
          key: 0,
          "selected-items": _ctx.localSigners,
          onOnAdd: _ctx.onAddSigner
        }, null, 8, ["selected-items", "onOnAdd"]))
      : _createCommentVNode("", true),
    (_ctx.popupState.updateText)
      ? (_openBlock(), _createBlock(_component_UpdateTextPopup, _normalizeProps(_mergeProps({ key: 1 }, _ctx.editSignerPopupInfo)), null, 16))
      : _createCommentVNode("", true)
  ], 64))
}
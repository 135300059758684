
import { defineComponent } from "vue";

export default defineComponent({
  name: "DragItem",
  props: {
    user: { type: Object, required: true },
    index: { type: Number, default: 0 },
  },
  emits: ["onEdit", "onDelete"],
  methods: {
    onEdit() {
      this.$emit("onEdit");
    },
    onDelete(index: number) {
      this.$emit("onDelete", index);
    },
  },
});

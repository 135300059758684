import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59cb5f6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "drag-item" }
const _hoisted_2 = { class: "item-leading" }
const _hoisted_3 = { class: "number order-number" }
const _hoisted_4 = { class: "mobile-drag-icon" }
const _hoisted_5 = { class: "info-row" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "ml-1" }
const _hoisted_8 = { class: "username" }
const _hoisted_9 = { class: "button-group" }
const _hoisted_10 = ["src"]
const _hoisted_11 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.index), 1),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("img", {
          class: "ml-1 bordered-avatar",
          src: require('icons/default-avatar.svg'),
          alt: "avatar"
        }, null, 8, _hoisted_6),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.user.placeHolder), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("img", {
        src: require('icons/edit.svg'),
        alt: "edit icon",
        class: "mr-2",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onEdit && _ctx.onEdit(...args)))
      }, null, 8, _hoisted_10),
      _createElementVNode("img", {
        src: require('icons/close-red-circle.svg'),
        alt: "remove icon",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDelete(_ctx.index - 1)))
      }, null, 8, _hoisted_11)
    ])
  ]))
}

import { defineComponent } from "vue";

export default defineComponent({
  name: "Chip",
  props: {
    text: { type: String, required: true },
    canRemove: { type: Boolean, default: false },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onRemove"],
  methods: {
    onRemove() {
      this.$emit("onRemove");
    },
  },
});

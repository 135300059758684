import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9ecb73c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["chip", { dense: _ctx.dense }])
  }, [
    _createElementVNode("p", null, _toDisplayString(_ctx.text), 1),
    (_ctx.canRemove)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onRemove && _ctx.onRemove(...args))),
          class: "close-icon",
          src: require('icons/close.svg'),
          alt: "close"
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true)
  ], 2))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f10099e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "group-select" }
const _hoisted_2 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chip = _resolveComponent("Chip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedItems, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "select-tag"
      }, [
        _createVNode(_component_Chip, {
          text: item.placeHolder,
          "can-remove": "",
          class: "mr-1 mb-1",
          onOnRemove: ($event: any) => (_ctx.removeItem(index))
        }, null, 8, ["text", "onOnRemove"])
      ]))
    }), 128)),
    _withDirectives(_createElementVNode("input", {
      class: "group-title",
      placeholder: _ctx.searchHint,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localPlaceHolder) = $event)),
      onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.createTag && _ctx.createTag(...args)), ["enter"]))
    }, null, 40, _hoisted_2), [
      [_vModelText, _ctx.localPlaceHolder]
    ])
  ]))
}
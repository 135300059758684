
import { defineComponent, PropType, ref } from "vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import TableSelect from "@/components/atomics/TableSelect.vue";
import Popup from "@/components/popups/PopupBase.vue";
import usePopupControl, {
  PopupKey,
} from "@/lib/compositional-logic/usePopupControl";

interface PlaceHolder {
  placeHolder: string;
}

export default defineComponent({
  name: "TemplateSignerAddPopup",
  components: {
    TableSelect,
    FlatButton,
    Popup,
  },
  emits: ["onAdd"],
  props: {
    selectedItems: {
      type: Array as PropType<Array<PlaceHolder>>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const selectedUsers = ref<PlaceHolder[]>([...props.selectedItems]);
    const placeHolder = ref("");

    const { closePopup } = usePopupControl();
    const popupKey: PopupKey = "templateSignerAdd";

    const onAdd = () => {
      if (
        selectedUsers.value.length < 5 &&
        placeHolder.value &&
        !selectedUsers.value.some(
          (selectedItem) => selectedItem.placeHolder == placeHolder.value
        )
      )
        selectedUsers.value = [
          ...selectedUsers.value,
          {
            placeHolder: placeHolder.value,
          },
        ];
      emit("onAdd", selectedUsers.value);
      closePopup(popupKey);
    };

    return {
      selectedUsers,
      onAdd,
      popupKey,
      placeHolder,
    };
  },
});
